<template>
        <b-overlay :show="isLoadingData">
            <b-card>
                <div class="head-btn">
                    <b-button
                        :to="{ name: 'create-newsletter'}"               
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"                                  
                    >
                        <feather-icon icon="PlusIcon" style="color: white" />
                    </b-button>
                </div> 
                <b-table
                    ref="refMembershipsListTable"
                    :items="listNewsletter"
                    :fields="tableColumns"
                    primary-key="id"
                    show-empty
                    empty-text="No hay registros"
                    class="position-relative mb-0"
                
                >
                    <template #cell(actions)="row">
                        <div>
                        <b-button
                            size="sm"
                            :to="{ name: 'edit-newsletter', params: { id: row.item.id} }"               
                           
                            variant="primary"
                        >
                            <feather-icon icon="Edit2Icon" size="12" />
                        </b-button>
                        </div>
                    </template>

                    <template #cell(status)="row">
                    
                        <div class="actions-status">
                            <b-badge pill :variant="row.item.status ? 'success' : 'danger'">
                                {{ row.item.status ? "Activo" : "Desactivado" }}
                            </b-badge>
                            <b-form-checkbox
                                :class="{ 'd-none': row.item.status }"
                                v-model="row.item.status"
                                v-b-tooltip.hover.v-warning
                                title="Cambiar Status"
                                :value="true"
                                switch
                                inline
                                :unchecked-value="false"
                                @change="changeStatus(row.item)"
                                class="custom-control-success"
                            >
                            </b-form-checkbox>
                        </div>
                      
                    </template>   
                </b-table>

            </b-card>
        </b-overlay>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import Ripple from 'vue-ripple-directive'
import { showAlertMessage } from "@/helpers/helpers";

export default {
    directives: { Ripple, },

    components: {
       
    }, 
    async created(){
        await this.init();
    },
    data(){
        return {
            isLoadingData: false,
            listNewsletter: [],
            tableColumns: [
                { key: 'languageName', label: 'Idioma' },
                { key: 'name', label: 'Nombre' },
                { key: 'description', label: 'description' }, 
                { key: 'link', label: 'link' }, 
                { key: 'status', label: 'status' },  
                { key: 'actions', label: 'Acciones', class: 'text-center' },
            ],
        }
    } ,     
    computed: {
        ...mapState('start', ["languages"]),
        ...mapState("auth", ["user"]),
    },
    methods: {  
        ...mapActions('fivesClubCatalogs', ["getInitialContentForNewsletter", "updateNewsLetters"]),
        ...mapMutations('start',['setLanguages']),

        async init(){
            this.isLoadingData = true
            const { newsLetters, languages } = await this.getInitialContentForNewsletter()
            this.listNewsletter = newsLetters
            if( !this.languages.length){
                this.setLanguages(languages)
            }
            this.isLoadingData = false
        },
        async changeStatus(data){
            this.isLoadingData = true
            const newsletter = JSON.parse( JSON.stringify( data ))
            newsletter.idUser = this.user.idUser

            const { status, message } = await this.updateNewsLetters(newsletter) // updateWebProp in backend

            if (status) {
                showAlertMessage( "Ok: Status actualizado", "InfoIcon", message, "success", 4000, "bottom-right" )
            }
            this.isLoadingData = false
            await this.init()
        }
    },
};
</script>

<style scoped>
.head-btn{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
}

.actions-status{
    display: flex;
    align-items: center;
    gap: 1rem;
}
</style>
